var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.allowCreate ? _c('div', {
    staticClass: "d-flex justify-content-end align-items-center mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickNew
    }
  }, [_vm._v("New " + _vm._s(_vm.friendlyName))])]) : _vm._e(), _c('DataTable', {
    attrs: {
      "items": _vm.items,
      "total": _vm.totalItems,
      "loading": _vm.loadingAction.list,
      "columns": _vm.columns,
      "page-size": 20,
      "fetch-items": _vm.getItems,
      "default-sort-key": "createdAt",
      "default-sort-direction": -1,
      "no-hover": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function (scope) {
          return [slot === 'actions' ? _c('div', {
            key: slot,
            staticClass: "d-flex"
          }, [_vm.allowEdit ? _c('button', {
            staticClass: "btn btn-sm btn-text-primary mr-2",
            on: {
              "click": function ($event) {
                return _vm.onClickEdit(scope.data);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil mr-2"
          }), _vm._v("Edit ")]) : _vm._e(), _vm.allowCreate ? _c('button', {
            staticClass: "btn btn-sm btn-text-danger",
            on: {
              "click": function ($event) {
                _vm.modals.delete = scope.data;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete ")]) : _vm._e(), _vm._t(slot, null, null, scope)], 2) : _vm._t(slot, null, null, scope)];
        }
      };
    })], null, true)
  }, [_vm._t("default", null, {
    "data": _vm.data
  })], 2), _vm.formComponent ? _c('Modal', {
    attrs: {
      "open": !!_vm.modals.edit,
      "title": _vm.friendlyName,
      "text": "Edit text...",
      "lg-size": "",
      "prevent": true,
      "loading": _vm.spinners.saving,
      "confirm-text": "Save"
    },
    on: {
      "close": function ($event) {
        _vm.modals.edit = false;
      },
      "submit": _vm.onSave
    }
  }, [_vm.modals.edit ? _c(_vm.formComponent, {
    tag: "component",
    model: {
      value: _vm.modals.edit.form,
      callback: function ($$v) {
        _vm.$set(_vm.modals.edit, "form", $$v);
      },
      expression: "modals.edit.form"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('Modal', {
    attrs: {
      "open": !!_vm.modals.delete,
      "title": "Delete Item",
      "prevent": true,
      "text": "Are you sure you want to delete this item?"
    },
    on: {
      "close": function ($event) {
        _vm.modals.delete = false;
      },
      "submit": _vm.onDelete
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }